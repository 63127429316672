import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import newArrivalsCatalogStyles from "./newArrivalsCatalog.module.scss"

import ProductCard from "../productCard/productCard"

function NewArrivalsCatalog({ collection }) {
  const newArrivalsCatalogQuery = useStaticQuery(graphql`
    query {
      women: shopifyCollection(title: { eq: "New Arrivals Women" }) {
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
      plus: shopifyCollection(title: { eq: "New Arrivals Plus" }) {
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
      maternity: shopifyCollection(title: { eq: "New Arrivals Maternity" }) {
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
    }
  `)

  const newArrivalsCatalog =
    collection === "women"
      ? newArrivalsCatalogQuery.women
      : collection === "maternity"
      ? newArrivalsCatalogQuery.maternity
      : newArrivalsCatalogQuery.plus

  const products = () => {
    return newArrivalsCatalog.products.map((product, i) => {
      return (
        <>
          {collection === "women" ? (
            <Link
              to={product.options[1] ? `/women/${product.handle}-${product.options[1].values[0]}`
                .replace(/ /g, "-")
                .toLowerCase() : "/women"}
              className={newArrivalsCatalogStyles.cardContainer}
              key={`${product.id}-${i}-new-arrivals-women`}
            >
              <ProductCard
                imgFront={product.images[0].localFile.childImageSharp.fluid}
                imgBack={product.images[1].localFile.childImageSharp.fluid}
                title={product.options[1] ? `${product.title} - ${product.options[1].values[0]}` : product.title}
                price={product.priceRange.minVariantPrice.amount}
                compareAt={product.variants[0].compareAtPrice}
                sizes={product.options
                  .filter(option => option.name === "Size")
                  .map(option => option.values)}
              />
            </Link>
          ) : collection === "maternity" ? (
            <Link
              to={product.options[1] ? `/maternity/${product.handle}-${product.options[1].values[0]}`
                .replace(/ /g, "-")
                .toLowerCase() : "/maternity"}
              className={newArrivalsCatalogStyles.cardContainer}
              key={`${product.id}-${i}-new-arrivals-maternity`}
            >
              <ProductCard
                imgFront={product.images[0].localFile.childImageSharp.fluid}
                imgBack={product.images[1].localFile.childImageSharp.fluid}
                title={product.options[1] ? `${product.title} - ${product.options[1].values[0]}` : product.title}
                price={product.priceRange.minVariantPrice.amount}
                compareAt={product.variants[0].compareAtPrice}
                sizes={product.options
                  .filter(option => option.name === "Size")
                  .map(option => option.values)}
              />
            </Link>
          ) : (
            <Link
              to={product.options[1] ? `/plus/${product.handle}-${product.options[1].values[0]}`
                .replace(/ /g, "-")
                .toLowerCase() : "/plus"}
              className={newArrivalsCatalogStyles.cardContainer}
              key={`${product.id}-${i}-new-arrivals-plus`}
            >
              <ProductCard
                imgFront={product.images[0].localFile.childImageSharp.fluid}
                imgBack={product.images[1].localFile.childImageSharp.fluid}
                title={product.options[1] ? `${product.title} - ${product.options[1].values[0]}` : product.title}
                price={product.priceRange.minVariantPrice.amount}
                compareAt={product.variants[0].compareAtPrice}
                sizes={product.options
                  .filter(option => option.name === "Size")
                  .map(option => option.values)}
              />
            </Link>
          )}
        </>
      )
    })
  }

  return (
    <div className={newArrivalsCatalogStyles.cardGridContainer}>
      <h2 className={newArrivalsCatalogStyles.heading}>{`${collection} New Arrivals`}</h2>
      <div className={newArrivalsCatalogStyles.cardGrid}>{products()}</div>
    </div>
  )
}

export default NewArrivalsCatalog
